<template>
    <div>
        <template v-if="isSelected">
            <v-tooltip top
                       max-width="240">
                <template v-slot:activator="{ on }">
                    <v-icon class="position-absolute"
                            style="right: 1.25rem; top: 1.25rem"
                            v-on="on">
                        mdi-information-variant
                    </v-icon>
                </template>
                {{ hint }}
            </v-tooltip>
        </template>
        <v-scroll-y-transition hide-on-leave>
            <div v-if="isSelected">
                <v-alert v-if="isSelected && !shopData.setting.show_account_number && computedStatus == 'wait_order'"
                         class="transparent mt-4"
                         color="primary"
                         type="info"
                         text>
                    {{ $t('เลขบัญชีโอนเงินจะถูกแสดงหลังยืนยันรายการ') }}
                </v-alert>
                <v-alert v-else
                         class="transparent mt-4 "
                         color="primary"
                         text>
                    <span class="small">{{ $t('เลขบัญชีจะแสดงชัดเจนอีกครั้งหลังกดปุ่มยืนยัน คุณจะสามารถคัดลอกเพื่อไปโอนเงินได้ทันที') }}</span>
                </v-alert>
                <div class="input-label-small mt-2">{{ $t('เลือกบัญชีธนาคารของร้านที่คุณจะโอนเงินเข้า') }} :</div>
                <v-radio-group dense
                               :rules="[v => !!v || $t('กรุณาเลือกบัญชีธนาคารด้วยนะคะ')]"
                               v-model="selectedProvider"
                               hide-details="auto">
                    <v-card outlined>
                        <v-list-item-group>
                            <div v-for="(bank, index) in bankProviders"
                                 :key="bank.lang + '_' + bank.key">
                                <label>
                                    <v-list-item :class="{ 'selected': (bank.key === selectedProvider)}">
                                        <v-list-item-action>
                                            <v-radio :value="bank.key"></v-radio>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="just_flex align-start">
                                                    <div>
                                                        <div class="text-body-2">{{ bank.lang }}</div>
                                                        <div class="text-caption disabled">
                                                            <span class="d-inline-block">
                                                                <template v-if="shopData.setting.show_account_number || !(computedStatus == 'wait_order' || computedStatus == 'wait_order_confirm')">
                                                                    {{ bank.detail.account_number }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ bank.detail.account_number.slice(0, 3) }}-x-xxxxx-{{ bank.detail.account_number.substr(-1, 1) }}
                                                                </template>
                                                            </span>
                                                            <span class="d-inline-block">&nbsp;· {{ bank.detail.account_name }}</span>
                                                        </div>
                                                    </div>
                                                    <template v-if="bank.detail.icon">
                                                        <img class="m-0"
                                                             :alt="bank.detail.name"
                                                             :src="bank.detail.icon.url">
                                                    </template>
                                                    <template v-else>
                                                        <img class="m-0"
                                                             :alt="bank.detail.name"
                                                             :src="`https://www.lnwpay.com/images/banks/${bank.detail.account_key}.jpg`">
                                                    </template>
                                                </div>
                                                <div v-if="showWarningLnwPayReconcile(bank.key)"
                                                     class="amber--text text--darken-2 mt-1"
                                                     style="font-size: 0.7em; font-weight: normal; max-width: 360px;">
                                                    {{ $t('สำหรับการโอนเงินเข้าบัญชีธนาคารนี้ คุณอาจจำเป็นต้องรอการตรวจสอบยอดเงินในช่วงเช้าวันทำการนะคะ') }}
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </label>
                                <v-divider v-if="index !== myPaymentMethod.length -1"></v-divider>
                            </div>
                        </v-list-item-group>
                    </v-card>
                </v-radio-group>
                <div class="buyerProtection just_flex"
                     v-if="myPaymentMethod.is_lnwpay">
                    <div>
                        <span>{{ $t('คุณจะได้รับความคุ้มครองผู้ซื้อโดย') }}</span>
                        <img style="height: 15px; vertical-align: text-top"
                             alt=""
                             src="@/assets/lnwpay_logo_gradient.png"/>
                    </div>
                    <v-tooltip class="d-inline-block mr-1"
                               max-width="300"
                               top>
                        <template v-slot:activator="{ on }">
                            <v-icon small
                                    v-on="on">
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                        <span v-html="$t('HOW2PAY-LNWPAY_TRANSFER_NOTICE')"></span>
                    </v-tooltip>
                </div>
            </div>
        </v-scroll-y-transition>
    </div>
</template>
<script>
import MixinsPaymentComponent from "./mixins/MixinsPaymentComponent.vue";
import {mapGetters, mapState} from 'vuex';
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "BankTransfer",
    mixins: [MixinsPaymentComponent, MixinsHelper],
    data(){
        return {
            snackbar: false,
        };
    },
    methods: {
        showWarningLnwPayReconcile(bank_key){
            if(this.myPaymentMethod.is_lnwpay
                && ['ktb', 'bbl'].includes(bank_key)
                && this.isTimeNotRightTime){
                return true;
            }
            return false;
        }
    },
    computed: {
        ...mapGetters({
            isWaitingShippingPrice: 'order/isWaitingShippingPrice',
        }),
        ...mapState({
            shopData: state => state.order.shopData,
            status: state => state.order.orderData?.order_status,
        }),
        isTimeNotRightTime(){
            const now = new Date();
            const hours = now.getHours();

            if(hours >= 23 || hours < 6){
                return true;
            }else{
                return false;
            }
        },
        computedStatus(){
            if(!this.status){
                return false;
            }
            if(this.isWaitingShippingPrice){
                return 'wait_order_confirm'
            }
            return this.status
        },
        bankProviders(){
            return this.myPaymentMethod.providers.filter(bank => bank.icon_key !== 'thaiqr');
        },
        hint(){
            return this.$t('ชำระเงินด้วยวิธีการโอนเงินโดยตรงผ่านบัญชีธนาคารผ่าน App ของธนาคาร, เคาน์เตอร์ธนาคาร, ตู้ ATM, Internet Banking');
        },
    },
}
</script>
<style scoped></style>