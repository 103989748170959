<template>
    <div>
        <div v-if="false && displayItems.length && showCart"
             class="mb-2 just_flex"
             style="min-height: 60px">
            <div class="grow">
                <template v-if="selectMode">
                    <v-checkbox v-if="showCart && selectMode"
                                :class="isDesktop?'px-4':''"
                                hide-details
                                style="top: 0.25rem;"
                                @click.prevent.stop="onClickSelectAll"
                                class="d-inline-block mt-0 position-relative"
                                :value="selectedAllItems"></v-checkbox>
                    <div @click="selectMode = !selectMode"
                         class="primary--text clickable d-inline-block align-middle"
                         style="height: 36px; line-height: 36px">
                        {{ $t('ยกเลิกการเลือก') }}
                    </div>
                </template>
                <template v-else>
                    <div @click="selectMode = !selectMode"
                         class="primary--text clickable d-inline-block"
                         style="height: 36px; line-height: 36px">
                        {{ $t('เลือกรายการ') }}
                    </div>
                </template>
            </div>
            <v-btn @click.stop="openDeleteItemDialog()"
                   :dark="(selectMode && selectedItems.length)?true:false"
                   :color="selectMode && selectedItems.length?'red':'#f5f5f5'"
                   :disabled="!selectMode || !selectedItems.length"
                   elevation="0">
                <v-icon dense>mdi-trash-can-outline</v-icon>
                <div class="ml-2"
                     v-if="selectedItems.length">{{ selectedItems.length }} {{ $t('รายการ') }}
                </div>
            </v-btn>
        </div>
        <v-alert type="error"
                 class="my-6"
                 v-if="displayItems.length <= 0">
            {{ $t('ไม่มีรายการสินค้า') }}
        </v-alert>
        <div class="items"
             :class="(!showCart && cpOrderItemsWithSubItems.length > 2)?'scrollY':''">
            <div :key="'item'+ item.iid"
                 v-for="item in displayItems"
                 class="item">
                <div class="item_row">
                    <div class="item_row_l">
                        <v-checkbox v-if="showCart && selectMode"
                                    :class="isDesktop?'px-4':''"
                                    v-model="selectedItems"
                                    :value="item.iid"></v-checkbox>
                        <div class="image">
                            <a target="_blank"
                               :href="item.url">
                                <v-badge color="grey darken-2"
                                         overlap
                                         :value="!(canEditProductQuantity && canThisItemEditProductQuantity(item.product_uuid))"
                                         :content="number_format(item.quantity)"
                                         style="font-weight: bold;"><img :src="item.thumbnail"
                                                                         :alt="item.name"/>
                                </v-badge>
                            </a>
                            <!--                            <div v-if="item.quantity > 1 && !expandOrderDetail"-->
                            <!--                                 class="quantity">-->
                            <!--                                x{{ item.quantity }}-->
                            <!--                            </div>-->
                        </div>
                    </div>
                    <div class="item_row_r">
                        <div class="tt"
                             :class="(!showCart && cpItemName(item) && cpItemName(item).length > 40)?'ttm':''">
                            <template v-if="item.errors?.length > 0">
                                <v-chip :key="'error'+ index"
                                        v-for="(error_item, index) in item.errors"
                                        color="red"
                                        dark
                                        class="mr-2 mb-2"
                                        style="max-width: 100%;"
                                        x-small
                                        label>
                                    {{ error_message(error_item) }}
                                </v-chip>
                            </template>
                            <v-chip v-if="item.is_preorder"
                                    class="
                                    mr-2 mb-2"
                                    x-small
                                    label>
                                Pre-order
                            </v-chip>
                            <v-tooltip top
                                       max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                    <div :ref="'item-name-'+ item.iid"
                                         v-bind="attrs"
                                         v-on="(cpItemName(item) && ((showCart && cpItemName(item).length > 200) || (!showCart && cpItemName(item).length > 40)))?on:''"
                                         @click="$refs['item-name-'+ item.iid][0].classList.add('tttF')"
                                         class="ttt" :class="(showCart && isDesktop)?'tttF':''">{{ cpItemName(item) }}
                                    </div>
                                </template>
                                <span>{{ cpItemName(item) }}</span>
                            </v-tooltip>
                            <div v-if="item.parent_name" class="text-sm-caption text-secondary">
                                {{ item.sku_name }}
                            </div>
                            <div v-if="showCart" class="mt-2"><span v-if="item.old_price" class="pr-2 text-decoration-line-through text-secondary">฿{{ number_format(item.old_price) }}</span>
                                <span>฿{{ number_format(item.unit_price) }}</span></div>
                            <template v-if="item.promotions && item.promotions.length > 0">
                                <v-tooltip top
                                           v-for="(promotion, index) in item.promotions"
                                           :key="'pro'+index"
                                           max-width="400">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="remark ttt promotion"
                                             :class="(showCart && isDesktop)?'tttF':''"
                                             v-bind="attrs"
                                             v-on="on">
                                            {{ promotion }}
                                        </div>
                                    </template>
                                    <span>{{ $t('สินค้าชิ้นนี้เข้าร่วมโปรโมชั่น') }} {{ promotion }}</span>
                                </v-tooltip>
                            </template>
                            <div v-if="expandOrderDetail">
                                <v-scroll-y-transition hide-on-leave>
                                    <div class="remark ttt"
                                         :class="(showCart && isDesktop)?'tttF':''"
                                         v-if="item.detail">
                                        {{ item.detail }}
                                    </div>
                                </v-scroll-y-transition>
                            </div>
                            <div v-if="canEditProductDetail"
                                 class="d-inline-block pt-1 primary--text clickable tiny_action"
                                 @click.stop="openDialogProductRemark(item)">
                                {{ item.detail ? $t('แก้ไข') : $t('เพิ่ม') }}{{ ($i18n.locale == 'th') ? "" : " " }}{{ $t('หมายเหตุ') }}
                            </div>
                        </div>
                        <component :is="(showCart && isDesktop)?'VCard':'DIV'"
                                   @click="changeQuantityDropdown(item, 'custom')"
                                   outlined
                                   flat
                                   v-if="canEditProductQuantity && canThisItemEditProductQuantity(item.product_uuid)"
                                   class="quantity"
                                   :class="showCart?'px-4 mx-2':''">
                            <template v-if="(changingQuantity == item.iid)">
                                <v-btn x-small style="min-width: 40px" height="40" loading text class="px-0 my-0"></v-btn>
                            </template>
                            <template v-else>
                                {{ item.quantity }}
                                <v-icon small
                                        :class="(showCart && isDesktop)?'py-3':''"
                                        color="primary">mdi-chevron-down
                                </v-icon>
                            </template>
                        </component>
                        <div v-if="expandOrderDetail"
                             class="amount">
                            <v-tooltip v-if="item.quantity>1"
                                       top
                                       right
                                       max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs"
                                         v-on="on"
                                         :class="{ 'minor' : item.total_price > 1000000}">
                                        <span class="baht">฿</span>{{ number_format(item.total_price, 2) }}
                                    </div>
                                </template>
                                <div>
                                    {{ (item.unit) ? $t('ละ', [item.unit]) : $t('ละ', ['ชิ้น']) }} ฿{{ number_format(item.unit_price, 2) }}
                                </div>
                            </v-tooltip>
                            <div v-else
                                 :class="{ 'minor' : item.total_price > 1000000}">
                                <span class="baht">฿</span>{{ number_format(item.total_price, 2) }}
                            </div>
                            <template v-if="false && canEditCart">
                                <v-menu bottom
                                        right
                                        v-if="canEditProductQuantity && canEditProductDetail">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="pt-1 d-inline-block primary--text clickable"
                                             v-on="on">
                                            {{ $t('แก้ไข') }}
                                        </div>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-if="canEditProductQuantity  && canThisItemEditProductQuantity(item.product_uuid)"
                                                     @click.stop="changeQuantityDropdown(item, 'custom')">
                                            <v-list-item-title>{{ $t('แก้ไขจำนวน') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="canEditProductDetail"
                                                     @click.stop="openDialogProductRemark(item)">
                                            <v-list-item-title>{{ item.detail ? $t('แก้ไข') : $t('เพิ่ม') }}{{ ($i18n.locale == 'th') ? "" : " " }}{{ $t('หมายเหตุ') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="canEditProductQuantity"
                                                     @click.stop="openDeleteItemDialog(item)">
                                            <v-list-item-title>
                                                {{ $t('ลบรายการนี้') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <template v-else-if="canEditProductQuantity && canThisItemEditProductQuantity(item.product_uuid)">
                                    <div class="pt-1 primary--text clickable"
                                         @click.stop="changeQuantityDropdown(item, 'custom')">
                                        {{ $t('แก้ไขจำนวน') }}
                                    </div>
                                </template>
                            </template>
                            <template v-if="showCart && canEditCart">
                                <div v-if="canEditProductQuantity" class="clickable primary--text mt-1" @click.stop="openDeleteItemDialog(item)">
                                    {{ $t('ลบออก') }}
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="item_row_b">
                        <!-- แสดง กล่อง Remark ให้ร้าน SodAPrinting -->
                        <div v-if="shopData.web_id == 887261 && canEditProductDetail && !item.detail"
                             class="just_flex_start remarkItem mt-0">
                            <v-textarea outlined
                                        dense
                                        height="42"
                                        hide-details
                                        :label="$t('เพิ่ม') + (($i18n.locale == 'th') ? '' : ' ') + $t('หมายเหตุสินค้า')"
                                        rows="1"
                                        v-model="remarkValue[item.iid]"></v-textarea>
                            <v-btn color="primary"
                                   large
                                   :disabled="!remarkValue[item.iid]"
                                   @click="changeItemRemark(item, remarkValue[item.iid]);"
                                   style="position:relative; top: -2px; font-size: 16px; margin-left: 17px;">
                                <v-icon color="white">mdi-arrow-right</v-icon>
                            </v-btn>
                        </div>
                        <template v-if="item.promotion_datas">
                            <template v-for="promotion_data in item.promotion_datas">
                                <div v-if="promotion_data.sub_item_max_quantity" class="subItem" :key="promotion_data.id">
                                    <sub-items-component :added_sub_items="item.promotion_sub_items[promotion_data.id] || []" :promotion_data="promotion_data" :item_iid="item.iid"></sub-items-component>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <div v-if="overall_item"
                 class="item overall_item">
                <div class="item_row">
                    <div class="item_row_b">
                        <template v-if="overall_item.promotion_datas">
                            <template v-for="promotion_data in overall_item.promotion_datas">
                                <div v-if="promotion_data.sub_item_max_quantity" class="subItem" :key="promotion_data.id">
                                    <sub-items-component :added_sub_items="overall_item.promotion_sub_items[promotion_data.id] || []" :promotion_data="promotion_data" :item_iid="overall_item.iid"></sub-items-component>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- dialog สำหรับลบสินค้า -->
        <v-dialog v-if="deleteItemDialog != null"
                  :retain-focus="false"
                  v-model="deleteItemDialog"
                  max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('ยืนยันลบรายการ') }} ?
                </v-card-title>
                <v-card-text v-if="deleteItemDialogItem">{{ deleteItemDialogItem.title??deleteItemDialogItem.name }}</v-card-text>
                <v-card-text v-else-if="deleteItemDialogItems.length">{{ $t('สินค้า') }} {{ deleteItemDialogItems.length }} {{ $t('รายการ') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color=""
                           text
                           @click="deleteItemDialog = false">
                        {{ $t('ปิด') }}
                    </v-btn>
                    <v-btn color="red darken-1"
                           text
                           @click="(deleteItemDialogItems.length)?deleteItems():deleteItem(deleteItemDialogItem); deleteItemDialog = false">
                        {{ $t('ยืนยันลบรายการ') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- dialog สำหรับระบุจำนวนสินค้า -->
        <v-dialog v-if="quantityDialogItem !== null"
                  v-model="quantityDialogItem"
                  max-width="290"
                  persistent
                  :retain-focus="false">
            <v-card class="quantityDialog">
                <v-card-title class="headline just_flex">
                    <div>{{ $t('จำนวนสินค้า') }}</div>
                    <div class="minor">{{ quantityDialogItem.unit }}</div>
                </v-card-title>
                <v-container>
                    <v-row>
                        <v-col md="4">
                            <v-btn height="50"
                                   v-if="quantityDialogValue == 1"
                                   depressed
                                   @click.stop="openDeleteItemDialog(quantityDialogItem)">
                                <v-icon small>mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <v-btn height="50"
                                   v-else
                                   depressed
                                   @click.stop="changeQuantityValue(-1)">
                                -
                            </v-btn>
                        </v-col>
                        <v-col md="4">
                            <v-text-field class="pt-0 mt-0 center"
                                          hide-details="auto"
                                          type="number"
                                          style="text-align: center"
                                          min="1"
                                          :rules="[value => !!value || 'Required.', value => value <= quantityDialogItem.remain || $t('สินค้านี้เหลือไม่เพียงพอ'), ]"
                                          v-model="quantityDialogValue"
                                          required></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-btn height="50"
                                   depressed
                                   @click="changeQuantityValue(1)">
                                +
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div v-if="orderData.is_show_remain && quantityDialogItem.remain !== 999666999"
                         class="center small"
                         :class="(quantityDialogItem.remain < 10)?'red--text':'grey--text'">
                        {{ $t('คงเหลือ') }} :
                        <template v-if="quantityDialogItem.remain !== 999666999">
                            {{ quantityDialogItem.remain }}
                        </template>
                        <template v-else>
                            {{ $t('ไม่จำกัด') }}
                        </template>
                    </div>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text
                           @click="quantityDialogItem = null">
                        {{ $t('ปิด') }}
                    </v-btn>
                    <v-btn color="primary"
                           text
                           :disabled="isAjaxLoading || (quantityDialogValue > quantityDialogItem.remain)"
                           @click="dialogSubmitItemQuantity(quantityDialogItem, quantityDialogValue)">
                        {{ $t('ตกลง') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- dialog สำหรับระบุ remark -->
        <v-dialog v-if="remarkDialogItem !== null"
                  v-model="remarkDialog"
                  max-width="400"
                  persistent
                  :retain-focus="false">
            <v-card>
                <v-card-title class="just_flex">
                    <div class="headline">{{ $t('หมายเหตุสินค้า') }}</div>
                    <v-btn icon
                           @click="remarkDialog = false; remarkDialogItem = null;">
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>{{ $t('ข้อความถึงร้านค้าเกี่ยวกับสินค้าชิ้นนี้') }}</v-card-text>
                <v-card-text>
                    <v-badge color="grey darken-2"
                             overlap
                             :value="true"
                             :content="number_format(remarkDialogItem.quantity)"
                             style="font-weight: bold; display: inline-block"><img width="40" style="border-radius: 5px" :src="remarkDialogItem.thumbnail"
                                                             :alt="remarkDialogItem.name"/>
                    </v-badge>
                    <div style="max-width: 70%; display: inline-block; white-space: nowrap" class="ml-4 text-overflow">{{ remarkDialogItem.name }}</div>
                </v-card-text>
                <v-card-text class="pb-0">
                    <v-textarea outlined
                                autofocus
                                :label="$t('ข้อความถึงร้าน')"
                                rows="3"
                                v-model="remarkDialogValue"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                           text
                           :disabled="isAjaxLoading"
                           @click="dialogSubmitItemRemark(remarkDialogItem, remarkDialogValue)">
                        {{ $t('ตกลง') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {VCard} from 'vuetify/lib';
import MixinsFormExpandOrderDetail from "@/components/Order/mixins/MixinsFormExpandOrderDetail.vue";
import MixinsTheOrderDetailMutual from "@/components/Order/mixins/MixinsTheOrderDetailMutual.vue";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import {mapActions, mapMutations, mapState} from "vuex";
import Helper from "@/libraries/Helper";
import SubItemsComponent from "@/components/Order/components/SubItemsComponent";

export default {
    name: "TheOrderItems",
    props: ['items'],
    data(){
        return {
            changingQuantity: false,
            quantityDialogItem: null,
            quantityDialogValue: null,
            remarkDialog: false,
            remarkDialogItem: null,
            remarkDialogValue: null,
            remarkValue: {},
            deleteItemDialog: false,
            deleteItemDialogItem: null,
            deleteItemDialogItems: [],
            selectMode: false,
            selectedItems: [],
            selectedAllItems: false,
        }
    },
    components: {
        SubItemsComponent,
        VCard,
    },
    mixins: [
        MixinsDisabledIconFocus,
        MixinsFormExpandOrderDetail,
        MixinsTheOrderDetailMutual,
        MixinsHelper,
    ],
    computed: {
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            isAjaxLoading: state => state.order.isAjaxLoading,
        }),
        showCart: {
            set(value){
                this.$store.commit('order/setForm', {key: 'show_cart', value: value});
            },
            get(){
                return this.$store.state.order.form.show_cart;
            },
        },
        displayItems() {
            if(this.items){
                return this.items
            }
            return this.cpOrderItems
        },
        overall_item: function() {
            return this.orderData.overall_item
        }
    },
    methods: {
        ...mapActions({
            actionChangeProductQuantity: 'order/changeProductQuantity',
            actionRemoveProductFromCart: 'order/removeProductFromCart',
            actionRemoveProductsFromCart: 'order/removeProductsFromCart',
            actionUpdateProductRemark: 'order/updateProductRemark',
            actionUpdateOrderDetail: 'order/updateOrderDetail',
            actionSetRedeemLnwPoint: 'order/setRedeemLnwPoint',
            actionSetRedeemShopPoint: 'order/setRedeemShopPoint',
        }),
        ...mapMutations({}),
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
        deleteItem(item){
            this.actionRemoveProductFromCart({iid: item.iid});
            this.deleteItemDialog = false;
            this.quantityDialogItem = null;
        },
        deleteItems(){
            if(!this.selectedItems) return false;
            this.actionRemoveProductsFromCart({iids: this.selectedItems});
            this.selectedItems = [];
            this.deleteItemDialog = false;
            this.quantityDialogItem = null;
        },
        changeQuantityDropdown(item, quantity){
            if(quantity === 'custom'){
                this.quantityDialogValue = item.quantity;
                this.quantityDialogItem = item;
            }else{
                this.changeItemQuantity(item, quantity);
            }
        },
        changeQuantityValue(adjust_quantity){
            let result_quantity = parseInt(this.quantityDialogValue) + parseInt(adjust_quantity);
            /*  ให้เกินได้แต่แสดง error แทน
            if(result_quantity > this.quantityDialogItem.remain){
                this.quantityDialogValue = this.quantityDialogItem.remain;
            }else*/
            if(result_quantity < 1){
                this.quantityDialogValue = 1;
            }else{
                this.quantityDialogValue = result_quantity;
            }
        },
        openDeleteItemDialog(item){
            if(this.checkNoPermissionAndPopup()) return;
            if(item){
                this.deleteItemDialogItem = item;
                this.deleteItemDialog = true;
            }else if(this.selectedItems.length){
                this.deleteItemDialogItems = this.selectedItems;
                this.deleteItemDialog = true;
            }

        },
        async dialogSubmitItemQuantity(item, quantity){
            this.changingQuantity = item.iid;
            await this.changeItemQuantity(item, quantity);
            this.changingQuantity = false;
            this.quantityDialogItem = null;
        },
        canThisItemEditProductQuantity(item_uuid){
            //กล่อง Box ของ Lalamove สั่งได้แค่ 1 ชิ้นเท่านั้น
            if(item_uuid === '11000853811000053'){
                return false;
            }
            return true;
        },
        async changeItemQuantity(item, quantity){
            await this.actionChangeProductQuantity({
                iid: item.iid,
                quantity: quantity,
            });
        },
        openDialogProductRemark(item){
            if(this.checkNoPermissionAndPopup()) return;
            this.remarkDialog = true;
            this.remarkDialogValue = item.detail;
            this.remarkDialogItem = item;
        },
        dialogSubmitItemRemark(item, remark){
            this.changeItemRemark(item, remark);
            this.remarkDialog = false;
            this.remarkDialogItem = null;
        },
        changeItemRemark(item, remark){
            let _item = this.cpOrderItems.filter((_item) => _item.iid === item.iid)
            if(_item.detail != remark){
                this.actionUpdateProductRemark({
                    iid: item.iid,
                    remark: remark,
                });
            }
        },
        selectAll(){
            this.selectedItems = [];
            this.cpOrderItems.forEach((item) => {
                this.selectedItems.push(item.iid);
            });
        },
        onClickSelectAll(){
            if(!this.selectedAllItems){
                this.selectAll();
            }else{
                this.selectedItems = [];
            }
        },
        cpItemName(item){
            return item.parent_name??item.name;
        }
    },
    mounted(){
        if(this.isDesktop){
            this.expandOrderDetail = true;
        }
    },
    watch: {
        selectedItems(newValue){
            if(newValue && newValue.length == this.cpOrderItems.length){
                this.selectedAllItems = true;
            }else{
                this.selectedAllItems = false;
            }
        },
    }
}
</script>
<style scoped></style>