<template>
    <div v-if="useThisItems && Object.keys(useThisItems).length > 0" class="cart-container cart-wrapper">
        <v-divider style="border-width: 4px" class="rounded mt-12"></v-divider>
        <div class="expanded">
            <div class="mt-12 mb-6">
                <b :style="isDesktop?'font-size: 1.75em':'font-size: 1.25em'">
                    <template v-if="item_type == 'related'">
                        {{ $t('คุณอาจจะชอบเช่นกัน') }}
                    </template>
                    <template v-else-if="item_type == 'recent-view'">
                        {{ $t('สินค้าที่คุณเพิ่งดู') }}
                    </template>
                </b>
            </div>
            <div class="items sub-items"
                 :layout="layout">
                <template v-for="(item, index) in useThisItems">
                    <ItemRow
                            v-if="showAll || index < ((isDesktop)?3:2)"
                         :changingQuantity="changingQuantity"
                         :key="'relate-items-' + item.id"
                         :item="item"
                         :can-add-more="true"
                         @deleteItem="deleteItem"
                         @addItem="addItem"/>
                    <v-divider v-if="showAll && (isDesktop?(index % 3 == 2):(index % 2 == 1))" :key="'sub-items-divider' + index"></v-divider>
                </template>
                <v-btn v-if="Object.keys(useThisItems).length > ((isDesktop)?3:2) && !showAll"
                       @click="showAll = true;"
                       x-large
                       text
                       class="show-more-button">
                    {{ $t('แสดงเพิ่มเติม') }}
                    <v-icon small
                            class="ml-1">mdi-chevron-down
                    </v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapActions, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import MixinsTheOrderDetailMutual from "@/components/Order/mixins/MixinsTheOrderDetailMutual";
import ItemRow from "@/components/Order/components/ItemRow";

export default {
    name: "RelatedItems",
    props: ['item_type'],
    data(){
        return {
            changingQuantity: false,
            layout: 'col',
            showAll: false
        }
    },
    mixins: [
        MixinsHelper,
        MixinsTheOrderDetailMutual
    ],
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet,
        ItemRow
    },
    computed: {
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            isAjaxLoading: state => state.order.isAjaxLoading,
        }),
        showCart: {
            set(value){
                this.$store.commit('order/setForm', {key: 'show_cart', value: value});
            },
            get(){
                return this.$store.state.order.form.show_cart;
            },
        },
        useThisItems() {
            if(this.item_type == 'related'){
                return Object.values(this.orderData.related_products);
            }
            if(this.item_type == 'recent-view'){
                return this.orderData.recent_view_products;
            }
            return [{}];
        }
    },
    methods: {
        ...mapActions({
            addProductToCart : 'order/addProductToCart',
            removeProductFromCart: 'order/removeProductFromCart',
        }),
        addItem(item){
            this.submitAddItem(item);
        },
        deleteItem(){
            //this.removeProductFromCart(item);
        },
        async submitAddItem(item,){
            let item_id = item.id ? item.id : item.relate_id;
            this.changingQuantity = item_id;
            let payload = {
                id : item_id
            };
            await this.addProductToCart(payload);
            this.scrollTo('#order-wrapper');
            this.changingQuantity = false;
        },
    },
    mounted(){
        // if(!this.isDesktop){
        //     this.layout = 'row';
        // }
    },
    watch: {}
}
</script>
<style scoped></style>