<script>
import Helper from "@/libraries/Helper.js";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "MixinsTheOrderDetailMutual",
    computed: {
        ...mapGetters({
            canEditProductQuantity: 'order/canEditProductQuantity',
            canEditProductDetail: 'order/canEditProductDetail',
        }),
        showCart: {
            set(value){
                this.$store.commit('order/setForm', {key: 'show_cart', value: value});
            },
            get(){
                return this.$store.state.order.form.show_cart;
            },
        },
        cpOrderItems() {
            if (this.$store.state.order.orderData) {
                return this.$store.state.order.orderData.items;
            }
            return [];
        },
        cpOrderItemsWithSubItems() {
            let items = [];
            this.cpOrderItems.forEach((item)=>{
                items.push(item);
                if(item.sub_items){
                    item.sub_items.forEach((subItem)=>{
                        items.push(subItem);
                    });
                }
            })
            return items;
        },
        cpOrderQuantity(){
            if(this.$store.state.order.orderData){
                return this.$store.state.order.orderData.items.reduce(function(prev, cur){
                    return prev + parseFloat(cur.quantity);
                }, 0);
            }
            return [];
        },
        cpOrderQuantityWithSubItems() {
            if (this.$store.state.order.orderData) {
                return this.$store.state.order.orderData.items.reduce((prev, item) => {
                    let totalQuantity = parseFloat(item.quantity);

                    if (item.sub_items) {
                        item.sub_items.forEach((subItem) => {
                            totalQuantity += parseFloat(subItem.quantity);
                        });
                    }

                    return prev + totalQuantity;
                }, 0);
            }
            return 0;
        },
        cpNetPrice() {
            if (this.$store.state.order.orderData) {
                // .net คือ จำนวนเงินที่ร้านจะได้
                // .pay คือ จำนวนเงินที่ลูกค้าต้องจ่าย
                return Helper.number_format(this.$store.state.order.orderData.price.pay, 2);
            }
            return '-';
        },
        hasShippingPrice() {
            if (this.$store.state.order.orderData) {
                if(this.$store.getters['order/isWaitingShippingPrice']){
                    return false;
                }
                return !!this.$store.state.order.orderData.shipping_type_id || this.$store.state.order.orderData.is_product_no_shipping_type;
            }
            return false;
        },
        canEditCart(){
            return this.canEditProductDetail || this.canEditProductQuantity;
        },
    },
    methods: {
        ...mapActions({
            actionTracker_beginCheckout: 'order/tracker_beginCheckout',
        }),
        toggleItemQuery(newValue) {
            const currentQueryParams = { ...this.$route.query };
            const webName = this.$route.params.web_name;
            const orderId = this.$route.params.order_id;

            let path = 'checkout';
            if (newValue) {
                path = 'cart';
            }
            const targetUrl = `/${path}/${webName}/${orderId}`;

            this.$router.push({
                path: targetUrl,
                query: currentQueryParams
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
        }
    },
    watch: {
        showCart(newValue) {
            if(!newValue){
                if(this.orderData?.order_status === "wait_order"){
                    this.actionTracker_beginCheckout();
                }
            }
            this.toggleItemQuery(newValue)
        },
        '$route'() {
            const path = this.$route.path;
            if (path.includes('cart')) {
                this.showCart = true;
            } else if (path.includes('checkout')) {
                this.showCart = false;
            }
        }
    },
}
</script>

<style scoped>

</style>