<template>
    <div class="item">
        <div class="item_row">
            <div class="item_row_l">
                <div class="image">
                    <a target="_blank"
                       :href="item.url">
                        <v-badge color="grey darken-2"
                                 overlap
                                 :value="!(canEditProductQuantity)"
                                 :content="number_format(item.quantity)"
                                 style="font-weight: bold;"><img :src="(item.thumbnail_2x)?item.thumbnail_2x:item.thumbnail"
                                                                 :alt="item.name"/>
                        </v-badge>
                    </a>
                </div>
            </div>
            <div class="item_row_r"
                 :class="(!item.quantity || item.quantity == 0)?'item_row_r_btn':''">
                <div class="tt"
                     :class="{'ttm': (cpItemName?.length > 200 || (!isDesktop && cpItemName?.length > 40)) }">
                    <template v-if="item.errors?.length">
                        <v-chip v-for="(error, index) in item.errors"
                                :key="'error' + index"
                                color="red"
                                dark
                                class="mr-2 mb-2"
                                x-small
                                label>
                            {{ error_message(error) }}
                        </v-chip>
                    </template>
                    <v-chip v-if="item.is_preorder"
                            class="mr-2 mb-2"
                            x-small
                            label>
                        Pre-order
                    </v-chip>
                    <v-chip v-if="showSubItemBadge"
                            class="mr-2 mb-2"
                            x-small
                            label>
                        {{ promotionType == 'addon' ? 'Add-on': 'Free Gift'}}
                    </v-chip>
                    <div v-if="item.ribbon_status" class="font-weight-bold text-uppercase mb-1 text-caption">
                        <span v-if="item.ribbon_status == 'hot'" class="red--text">
                            {{ $t('ขายดี') }}
                        </span>
                        <span v-else-if="item.ribbon_status == 'sale'" class="red--text">
                            {{ $t('ลดราคา') }}
                        </span>
                        <span v-else-if="item.ribbon_status == 'new'" class="primary--text">
                            {{ $t('ใหม่') }}
                        </span>
                        <span v-else-if="item.ribbon_status == 'recommend'" class="primary--text">
                            {{ $t('แนะนำ') }}
                        </span>
                    </div>
                    <v-tooltip top
                               max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs"
                                 v-on="(cpItemName.length > 200 || (!isDesktop && cpItemName.length > 40)) ? on : ''"
                                 class="ttt text--primary">
                                {{ cpItemName }}
                            </div>
                        </template>
                        <span class="text--primary">{{ cpItemName }}</span>
                    </v-tooltip>
                    <div v-if="cpSubItemName" class="text-sm-caption text-secondary">
                        {{ cpSubItemName }}
                    </div>
                    <div v-if="showCart || isDialog"
                         class="mt-2"><span v-if="item.old_price"
                                            class="pr-2 text-decoration-line-through text-secondary">฿{{ number_format(item.old_price) }}</span>
                        <span :class="item.old_price?'red--text':''">฿{{ number_format(item.unit_price) }}</span></div>
                    <template v-if="item.promotions?.length">
                        <v-tooltip top
                                   max-width="400"
                                   v-for="(promotion, index) in item.promotions"
                                   :key="'pro' + index">
                            <template v-slot:activator="{ on, attrs }">
                                <div class="remark ttt promotion" :class="(showCart && isDesktop)?'tttF':''"
                                     v-bind="attrs"
                                     v-on="on">
                                    {{ promotion }}
                                </div>
                            </template>
                            <span>{{ $t('สินค้าชิ้นนี้เข้าร่วมโปรโมชั่น') }} {{ promotion }}</span>
                        </v-tooltip>
                    </template>
                    <v-scroll-y-transition hide-on-leave>
                    <div v-if="item.detail">
                        <div class="remark ttt" :class="(showCart && isDesktop)?'tttF':''">{{ item.detail }}</div>
                    </div>
                    </v-scroll-y-transition>
                    <div v-if="item.quantity && canEditProductDetail"
                         class="d-inline-block pt-1 primary--text clickable tiny_action"
                         @click.stop="handleOpenDialogSubItemRemark(item)">
                        {{ item.detail ? $t('แก้ไข') : $t('เพิ่ม') }}{{ ($i18n.locale == 'th') ? "" : " " }}{{ $t('หมายเหตุ') }}
                    </div>
                </div>
                <template v-if="item.quantity">
                    <component v-if="canEditProductQuantity"
                               :is="((showCart || isDialog) && isDesktop)?'VCard':'DIV'"
                               @click="handleChangeQuantityDropdown()"
                               outlined
                               flat
                               class="quantity"
                               :class="((showCart || isDialog) && isDesktop)?'px-4 mx-2 py-2':''">
                        <template v-if="(changingQuantity && changingQuantity === useThisId)">
                            <v-btn x-small
                                   loading
                                   text
                                   color="primary"
                                   class="px-0"></v-btn>
                        </template>
                        <template v-else>
                            {{ item.quantity }}
                            <v-icon small
                                    color="primary">mdi-chevron-down
                            </v-icon>
                        </template>
                    </component>
                    <div class="amount">
                        <v-tooltip v-if="item.quantity > 1"
                                   top
                                   right
                                   max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs"
                                     v-on="on"
                                     class="text--primary"
                                     :class="{ 'minor': item.quantity * item.unit_price > 1000000 }">
                                    <span class="baht">฿</span>{{ number_format(item.quantity * item.unit_price, 2) }}
                                </div>
                            </template>
                            <div>
                                {{ (item.unit) ? $t('ละ', [item.unit]) : $t('ละ', ['ชิ้น']) }} ฿{{ number_format(item.unit_price, 2) }}
                            </div>
                        </v-tooltip>
                        <div v-else
                             class="text--primary"
                             :class="{ 'minor': item.quantity * item.unit_price > 1000000 }">
                            <span class="baht">฿</span>{{ number_format(item.quantity * item.unit_price, 2) }}
                        </div>
                        <template v-if="showCart">
                            <div v-if="canEditProductQuantity" class="clickable primary--text mt-1" @click.stop="handleDeleteItem">
                                {{ $t('ลบออก') }}
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="button"
                         :style="isDesktop?'min-width: 245px':'min-width: 70px'">
                        <v-btn :disabled="!canAddMore || item.remain === 0"
                               @click="handleAddItem"
                               :loading="changingQuantity && changingQuantity === useThisId"
                               large
                               :x-large="isDesktop"
                               block
                               color="primary"
                               class="button">
                            <template v-if="item.remain > 0">
                                {{ isDesktop?$t('เพิ่มลงตะกร้า'):$t('เพิ่ม') }}
                            </template>
                            <template v-else>
                                {{ isDesktop?$t('สินค้าหมด'):$t('หมด') }}
                            </template>

                        </v-btn>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import {VCard} from 'vuetify/lib';
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import {mapGetters} from "vuex";

export default {
    props: [
        'item',
        'changingQuantity',
        'changingRemark',
        'isDialog',
        'canAddMore',
        'showSubItemBadge',
        'promotionType'
    ],
    mixins: [
        MixinsHelper,
    ],
    components: {
        VCard,
    },
    methods: {
        handleChangeQuantityDropdown(){
            this.$emit('changeQuantityDropdown', this.item, 'custom');
        },
        handleOpenDialogSubItemRemark(){
            this.$emit('openDialogSubItemRemark', this.item);
        },
        handleDeleteItem(){
            this.$emit('deleteItem', this.item);
        },
        handleAddItem(){
            this.$emit('addItem', this.item, 1);
        },
    },
    computed: {
        ...mapGetters({
            canEditProductQuantity: 'order/canEditProductQuantity',
            canEditProductDetail: 'order/canEditProductDetail',
        }),
        showCart: {
            set(value){
                this.$store.commit('order/setForm', {key: 'show_cart', value: value});
            },
            get(){
                return this.$store.state.order.form.show_cart;
            },
        },
        useThisId(){
            if(this.item.iid){
                return this.item.iid;
            }
            if(this.item.id){
                return this.item.id;
            }
            if(this.item.related_id){
                return this.item.related_id;
            }
            return false;
        },
        cpItemName() {
            if(this.item.title){
                if(this.item.parent){
                    return this.item.parent.name;
                }
               return this.item.title;
            }
            return this.item.parent_name??this.item.name;
        },
        cpSubItemName() {
            if(this.item.title){
                if(this.item.parent){
                    return this.item.name;
                }
                return null;
            }
            if(this.item.parent_name){
                return this.item.sku_name;
            }
            return null;
        }
    }
}
</script>
<style scoped>
/* Add your styles here */
</style>